// API
// ----------------------------------------------------------------------

// export const HOST_API = process.env.NEXT_PUBLIC_HOST_API;
// export const ASSETS_API = process.env.NEXT_PUBLIC_ASSETS_API;
export const HOST_API = '/';
export const ASSETS_API = '/';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = '/dashboard';

export const SITE_TITLE = 'PEACE MES';
